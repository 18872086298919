.header {
  background-color: var(--primary);
  color: var(--white);
  padding: 1.5rem 0;
  min-height: 6rem;
  width: 100%;

  .header__branding {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--white);
    text-decoration: none;

    grid-template-columns: max-content 1fr;
    gap: .4rem .8rem;

    &-logo {
      grid-row: 1 / 3;
    }

    &-image {
      background-color: var(--white);
      border-radius: 50%;
      border: 1px solid #00dbcd;
      height: 100%;

    }

    &-text {
      font-weight: 600;
      font-size: 1.2rem;

      line-height: 1.7;
    }
  }
}

.server-status  {
  display: flex;
  gap: .3rem;
  grid-row: 2 / 3;
  grid-column: 2 / -1;

  div {
    width: .6rem;
    height: .6rem;
    border-radius: .4px;
  }

  &__up {
    background-color: var(--alert-success);
  }

  &__down {
    background-color: var(--alert-danger);
  }


}