.user {
  padding-bottom: 3.2rem;
  border-bottom: 2px solid var(--divider);
  @include for-medium {
    padding-bottom: 0;
    border: none;
  }

  .card__body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card__footer {
    border-top: 0;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }

  &__info {
    border: 1px solid var(--divider);
    border-radius: .4rem;
    padding-bottom: .6rem;

    .chart-summary {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__ratio {
    padding: 0 1.6rem;

    &-caption {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: medium;
      opacity: .87;
      color: var(--primary);
    }

    &-percent {
      display: flex;
      align-items: flex-end;
      gap: .8rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &-main {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3.6rem;
        color: var(--primary);
      }

      &-sub {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 3.6rem;
        display: flex;
        align-items: center;
        gap: .2rem;

        &--danger {
          color: var(--alert-danger);
        }

        &--success {
          color: var(--alert-success);
        }
      }
    }
  }
  &__chart {
    padding: 1.6rem 0;

  }
}

.user-chart {
  &__caption {
    margin-bottom: 1.6rem;
    span {
      font-size: 1.6rem;
    }
  }
}