.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #25265E;

  .login-title {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 44px;
    text-align: center;
  }
}