.support {
  margin-top: 3.2rem;
  padding-bottom: 3.2rem;
  border-bottom: 2px solid var(--divider);

  @include for-medium {
    padding-bottom: 0;
    border: none;
    margin-top: 0;
  }
}

.support-progress {
  color: rgba(255, 255, 255, 0.87);
}

.unresolved {
  background-color: #6501E5;
}

.resolved {
  background-color: #B3A6FB;
}

.content {
  background-color: #664EF6;
}

.dev {
  background-color: #5CC9CA;
}

.payment {
  background-color: #F5BE51;
}

.other {
  background-color: #C903EA;
}