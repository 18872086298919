.statistics {
  @include for-medium {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(2, 18vw);
    grid-template-rows: repeat(2, 28rem);
    gap: 2.4rem;

    .user {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    .sales {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }

    .support {
      grid-row: 1 / 2;
      grid-column: 3 / 4;
    }

    .traffic {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
    }

  }
  margin-bottom: 1.6rem;
}