.container {
  max-width: 160rem;
  width: 90%;
  margin: 0 auto;
}

//////////////////////////////////
// Background
.bg-primary {
  background-color: var(--primary);
}
.bg-white {
  background-color: var(--white);
}
.bg--grey {
  background-color: #f5f5f5;
}
.bg-success {
  background-color: var(--alert-success);
}
.bg-danger {
  background-color: var(--alert-danger);

  &--high {
    background-color: var(--alert-danger);
  }

  &--medium {
    background-color: var(--alert-danger-medium);
  }

  &--low {
    background-color: var(--alert-danger-low);
  }
}
//////////////////////////////////

// TYPOGRAPHY
.text-primary {
  color: var(--primary);
}
.text-white {
  color: var(--white);
}
.text-success {
  color: var(--alert-success);
}
.text-danger {
  color: var(--alert-danger);
}
///////////////////////////////////


// display
.d-none {
  display: none;
}
.d-md-none {
  @include for-medium {
    display: none;
  }
}
.d-md-block {
  @include for-medium {
    display: block;
  }
}



