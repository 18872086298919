.sub-header {
  background-color: var(--white);
  padding: 2rem 0;
  box-shadow: .3rem .3rem .5rem var(--divider);
  margin-bottom: 3rem;
  font-weight: 600;

  .form-control {
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__input {
    &__text {
      margin-left: 8px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: .4rem;

    @include for-medium {
      flex-direction: row;
      align-items: flex-end;
    }

    .heading-2 {
      line-height: 1;
    }

  }



  &__subtitle {
    font-size: 1.4rem;
    opacity: .87;
    color: #26275e;
    font-weight: 500;
  }

  @include for-medium {
    background-color: transparent;
    box-shadow: none;
    padding-bottom: 0;
  }
}