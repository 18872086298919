.card {
  &__header {
    border: 1px solid var(--divider);
    background-color: #f5f5f5;

    @include for-medium {
      background-color: transparent;
      border: none;
      padding: 0;
      padding-bottom: .5rem;
    }

    padding: 1.6rem;
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);

    &-title h3 {
      font-weight: 500;
    }
  }

  &__subtitle {
    margin-top: .2rem;
  }

  &__body {
    background-color: var(--white);
    padding: 1.6rem;
    border: 1px solid var(--divider);
    @include for-medium {
      border-radius: .4rem;
    }
    
  }

  &__footer {
    padding: 1.6rem;
    background-color: #f8f8f8;
    border: 1px solid var(--divider);

    &-main {
      color: var(--primary);
      font-size: 2.4rem;
      font-weight: 600;
      margin-top: .4rem;
      line-height: 3.6rem;
    }
  }


}
