@font-face {
  font-family: 'Euclid';
  font-weight: 400;
  src: local('Euclid'), url('../fonts/EuclidCircularA-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: 500;
  src: local('Euclid'), url('../fonts/EuclidCircularA-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: 600;
  src: local('Euclid'), url('../fonts/EuclidCircularA-Semibold.otf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: 700;
  src: local('Euclid'), url('../fonts/EuclidCircularA-Bold.otf') format('truetype');
}