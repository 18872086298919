.heading {
  &-2 {
    font-size: 2.2rem;
    line-height: 3rem;
    font-weight: 600;
  }

  &-3 {
    font-size: 2rem;
    line-height: 3rem;

  }

  &--primary {
    color: var(--primary);
  }

  &--dark {
    color: var(--dark);
  }
}
