.sales {
  margin-top: 3.2rem;
  padding-bottom: 3.2rem;
  border-bottom: 2px solid var(--divider);

  @include for-medium {
    border-bottom: none;
    margin-top: 0;
    padding-bottom: 0;
  }

  &__chart {
    padding-bottom: 32px;
  }

  &__direct {
    border-bottom: 1px solid var(--divider);
    padding-bottom: 32px;
    padding-top: 1.6rem;

    &-caption {
      margin-bottom: 1.2rem;

      .subtitle {
        opacity: 1;
      }
    }
  }

  &__trial {
    // margin-top: 1.4rem;
    padding-top: 1.6rem;
    padding-bottom: 40px;

    .subtitle {
      font-size: 1.6rem;
      font-weight: normal;
      margin-bottom: 4px;
    }

    &-ratio {
      display: flex;
      gap: .8rem;
      align-items: center;
    }

    &-percent {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 3.6rem;

      &--primary {
        color: var(--primary);
      }
    }

    &-status {
      display: flex;
      align-items: center;
      gap: .4rem;

      &-percent {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 500;

        &--danger {
          color: var(--alert-danger);
        }

        &--success {
          color: var(--alert-success);
        }
      }
    }
  }
}

.sales-chart {
  padding-top: 0;

  &__caption {
    padding-bottom: 1.6rem;

    span {
      font-size: 1.6rem;
    }
  }
}

.sales-progress {
  color: rgba(37, 38, 94, 0.67);
  margin-top: 8px;
  margin-bottom: .2rem;
}

.monthly {
  background-color: #F5BE51;
}

.monthly-trial {
  background-color: #FFE2AA;
}

.yearly {
  background-color: #C903EA;
}

.yearly-trial {
  background-color: #F3ABFF;
}