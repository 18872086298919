@import "./login";
@import "./card/card";

@import "./header/header";
@import "./subheader/sub-header";

@import "./statistics/statistics";

@import "./inputs/inputs";

@import "./user-acquisiton/user-acquisition";
@import "./sales/sales";
@import "./traffic/traffic";
@import "./support/customer-support";

.dashboard-fix {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .dashboard-fix-child {
    width: 50%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.status {
  display: flex;
  align-items: center;
  gap: .4rem;

  &-percent {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;

    &--danger {
      color: var(--alert-danger);
    }

    &--success {
      color: var(--alert-success);
    }
  }
}

.chart-legends {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &__item {
    display: flex;
    align-items: center;
    margin: 0 8px;
    line-height: 16px;
    font-size: 12px;
    font-weight: normal;

    &__image {
      margin: 0 0 0 4px;
      width: 32px;
    }
  }
}
