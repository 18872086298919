.traffic {
  margin-top: 3.2rem;
  padding-bottom: 3.2rem;

  @include for-medium {
    padding-bottom: 0;
    margin-top: 0;
  }

  &__data {
    margin-top: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__domain {
    font-size: 2.4rem;
    line-height: 2.6rem;
    font-weight: 600;
  }

  &__percent {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3.6rem;
    color: var(--white);
  }
}

.traffic {
  &__chart {
  }
}