.select {
  padding: 0.9rem 1.7rem;
  font-weight: 500;
  color: var(--primary);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  font-size: 1.4rem;
}


