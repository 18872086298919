$xs: 0px;
$sm: 600px;
$md: 1200px;

:root {
  --primary: #26275e;
  --white: #fff;
  --dark: #222;
  --grey: #f1f3f4;

  --alert-success: #56bd5b;
  --alert-danger: rgba(227, 77, 77, 1);
  --alert-danger-medium: rgba(227, 77, 77, .75);
  --alert-danger-low: rgba(227, 77, 77, .5);

  --divider: #d3dce6;

  --transit: all .2s ease;

  --radius: .4rem;

  // scss-docs-start progress-variables
  --progress-height: 1rem;
  --progress-bg: #e9ecef;
  --progress-border-radius: .375rem;
  --progress-box-shadow: inset 0 1px 2px rgba(#000, .075);
  --progress-bar-color: #fff;
  --progress-bar-bg: #0d6efd;
  --progress-bar-transition: width .6s ease;
  // scss-docs-end progress-variables

}