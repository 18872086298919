.chart-summary {
  background-color: var(--primary);
  color: var(--white);
  padding: 1.6rem;
  border-radius: .4rem;
  margin-bottom: 1.6rem;

  &__visits {
    border-right: 1px solid var(--divider);
    flex: 0 0 50%;
  }

  &__signed {
    flex: 0 0 50%;
    margin-left: 2.4rem;
  }

  &--flex {
    display: flex;
    justify-content: space-between;
  }

  &__ratio {
    display: flex;
    gap: .5rem;
  }
  

  &__caption {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    opacity: .87;
  }

  &__subcaption {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;

    color: var(--alert-success);
  }

  &__count {
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 600;
  }

  &__percent {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 500;

    display: flex;
    gap: .2rem;
    align-items: center;

    

    &--success {
      color: var(--alert-success);
    }
    &--danger {
      color: var(--alert-danger);
    }
  }
}