*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  box-sizing: border-box;
  font-family: 'Euclid', sans-serif;
  background-color: var(--grey);
  color: #25265E;
}

img {
  display: block;
}

.progress {
  display: flex;
  height: var(--progress-height);
  overflow: hidden;
  background-color: var(--progress-bg);
  border-radius: var(--progress-border-radius);
  box-shadow: var(--progress-box-shadow);

}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--progress-bar-bg);
  transition: var(--progress-bar-transition);
}

.progress-caption {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;

  &____label-left {
    width: 75px;
    height: 16px;
  }

  &____label-right {
    width: 61px;
    height: 16px;
  }
}

.progress-legends {
  display: flex;
  justify-content: space-around;
  color: rgba(37, 38, 94, 0.67);
  margin-top: 16px;

  &__item {

    display: flex;
    align-items: baseline;

    &-icon {
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }

    &-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-left: 4px;
    }

  }
}